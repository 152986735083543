<template>
  <swiper-slide class="swiper-slide-0" >
    <div class="logo" :to="{ path: '/' }"></div>
    <!-- <img class="icon-person big icon-0" :src="image.pv_logo_b"/> -->
    <img class="icon-person big icon-0" :src="require('@/assets/index/logo.png')"/>
    <div class="title-0">HI ，请让我了解您的出行需求！</div>
    <div class="sub-title-0">
      约1分钟 ( 4个问题 ) 定制你的行程<br />1对1定制建议提前十天，或请直接联系定制师>>
    </div>
    <div class="footer-btn">
      <i class="icon"></i>
      <div class="text">滚动鼠标开始定制</div>
    </div>
  </swiper-slide>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SwiperSlide0",
  computed:{
    image() {
      return this.$store.state.custom.images;
    },
  },
  created() {
    this.clearOrder()
  },
  methods: {
    ...mapActions([ "clearOrder" ]),
  }
};
</script>

<style lang="scss" scoped>
.logo {
  display: inline-block;
  width: 214px;
  height: 214px;
  background-size: 100% 100%;
  margin: 42px 0 0 148px;
}
.icon-0 {
  margin: 0 auto;
  margin-top: -10vh;
}
.title-0 {
  margin-top: 75px;
  text-align: center;
  height: 58px;
  font-size: 45px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(254, 254, 254, 1);
  margin-bottom: 50px;
}
.sub-title-0 {
  text-align: center;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(254, 254, 254, 1);
  line-height: 50px;
}
.footer-btn {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 258px;
  height: 66px;
  background: url(../../../assets/index/icon-030.png) no-repeat;
  .icon {
    display: block;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    background: url(../../../assets/index/icon-029.png) no-repeat;
  }
  .text {
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(207, 169, 114, 1);
  }
}
</style>
