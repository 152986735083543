<template>
  <div class="wrap">
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide-0></swiper-slide-0>
      <swiper-slide-6></swiper-slide-6>
      <swiper-slide-7></swiper-slide-7>
      <swiper-slide-9></swiper-slide-9>
      <swiper-slide-10></swiper-slide-10>
      <swiper-slide-11></swiper-slide-11>
      <swiper-slide-12></swiper-slide-12>
      <swiper-slide-14></swiper-slide-14>
      <swiper-slide-15></swiper-slide-15>
      <swiper-slide-16></swiper-slide-16>
      <swiper-slide-17></swiper-slide-17>
      <swiper-slide-1></swiper-slide-1>
      <swiper-slide-2></swiper-slide-2>
      <swiper-slide-3></swiper-slide-3>
      <swiper-slide-5></swiper-slide-5>
    </swiper>
  </div>
</template>

<script>
import { getPrivateCustomInfo } from '@/api';
const modulesFiles = require.context('./components', true, /\.vue$/);
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
export default {
  name: 'PrivateCustom',
  components: {
    ...modules,
  },
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        height: window.innerHeight,
        slidesPerView: 1,
        clickable: false,
        mousewheel: true,
        lazy: {
          loadPrevNext: true,
        },
        shortSwipes: false,
      },
      privateCustomInfo: {},
    };
  },
  mounted() {
    console.log(this.$refs.mySwiper);
  },
  created() {
    this.getPrivateCustomInfoFunc();
  },
  methods: {
    getPrivateCustomInfoFunc() {
      getPrivateCustomInfo()
        .then((res) => {
          console.log(11, res);
          this.privateCustomInfo = res;
          this.$store.dispatch('setImages', this.privateCustomInfo);
        })
        .catch(() => {});
    },
    slideNext(index) {
      this.$refs.mySwiper.$swiper.slideTo(index, 1000, false);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap,
.swiper-container {
  height: 100%;
}
:deep(.icon-person) {
  display: block;
  &.big {
    width: 172px;
    height: 172px;
  }
  &.middle {
    width: 120px;
    height: 120px;
  }
  &.small {
    width: 61px;
    height: 61px;
  }
}
// :deep(.title) {
.title {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  &-c {
    background: url(../../assets/index/icon-032.png) no-repeat;
    background-size: 100% 100%;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    &.middle {
      width: 657px;
      height: 84px;
      line-height: 84px;
      margin-left: -8px;
      font-size: 30px;
      margin-bottom: -8px;
    }
    &.small {
      width: 333px;
      height: 42px;
      line-height: 42px;
      font-size: 15px;
      margin-left: -9px;
      margin-bottom: -5px;
    }
  }
}
:deep(.el-input__inner) {
  background-color: rgba(255, 255, 255, 0.41);
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 0px;
  color: #fff;
  opacity: 0.8;
}
:deep(.el-select) {
  width: 100%;
  :deep(.el-input__inner) {
    height: unset;
    background-color: #cea871;
    border: 1px solid #cea871;
  }
}
:deep(.el-range-input) {
  background: none;
}
.swiper-slide-0 {
  background: url(../../assets/index/icon-031.png) no-repeat;
  position: relative;
}
.swiper-slide-1 {
  background: url(../../assets/index/icon-038.png) no-repeat;
}
.swiper-slide-2 {
  background: url(../../assets/index/icon-038.png) no-repeat;
}
.swiper-slide-3 {
  background: url(../../assets/index/icon-038.png) no-repeat;
}
.swiper-slide-4 {
  background: url(../../assets/index/icon-038.png) no-repeat;
}
.swiper-slide-5 {
  background: url(../../assets/index/icon-041.png) no-repeat;
}
.swiper-slide-6 {
  background: url(../../assets/index/icon-044.png) no-repeat;
}
.swiper-slide-7 {
  background: url(../../assets/index/icon-033.png) no-repeat;
}
.swiper-slide-8 {
  background: url(../../assets/index/icon-036.png) no-repeat;
}
.swiper-slide-9 {
  background: url(../../assets/index/icon-037.png) no-repeat;
}
.swiper-slide-10 {
  background: url(../../assets/index/icon-036.png) no-repeat;
}
.swiper-slide-11 {
  background: url(../../assets/index/icon-038.png) no-repeat;
}
.swiper-slide-12 {
  background: url(../../assets/index/icon-039.png) no-repeat;
}
.swiper-slide-13 {
  background: url(../../assets/index/icon-039.png) no-repeat;
}
.swiper-slide-14 {
  background: url(../../assets/index/icon-040.png) no-repeat;
}
.swiper-slide-15 {
  background: url(../../assets/index/icon-041.png) no-repeat;
}
.swiper-slide-16 {
  background: url(../../assets/index/icon-042.png) no-repeat;
}
.swiper-slide-17 {
  background: url(../../assets/index/icon-043.png) no-repeat;
}
.swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}
</style>