<template>
  <swiper-slide class="swiper-slide-9">
    <div class="logo" :to="{ path: '/' }"></div>
    <div class="title title-6">
      <img class="icon-person big icon-0" :src="require('@/assets/index/logo.png')"/>
      <div class="title-c middle">旅游除了金钱，我们还需要奢侈的时间。</div>
    </div>
  </swiper-slide>
</template>

<script>
export default {
  name: "SwiperSlide9",
  computed:{
    image() {
      return this.$store.state.custom.images;
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: inline-block;
  width: 214px;
  height: 214px;
  background-size: 100% 100%;
  margin: 42px 0 0 148px;
}
.icon-0 {
  margin: 0 auto;
  margin-top: -10vh;
}
.title-6 {
  margin-top: 75px;
  text-align: center;
  height: 58px;
  font-size: 35px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(254, 254, 254, 1);
  margin-bottom: 50px;
  .title-c {
    margin-top: 20px;
  }
}
</style>
