<template>
  <swiper-slide class="swiper-slide-9">
    <div class="div-9">
      <div class="title title-9">
        <img
          class="icon-person small"
          :src="require('@/assets/index/logo.png')"
        />

        <!-- <img class="icon-person small" :src="image.pv_logo_s"/> -->

        <div class="title-c small">再次确认一下，你的定制需求是这样的</div>
      </div>

      <div class="content">
        <div v-for="item in suggestions" :key="item.label">
          <span>
            {{ item.label }}:
            <span class="editable">{{ item.value }}</span>
          </span>

          <!-- <i class="el-icon-edit-outline" @click="edit(item)"></i> -->
        </div>
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
export default {
  name: 'SwiperSlide17',
  computed: {
    ...mapState(['custom']),
    image() {
      return this.$store.state.custom.images;
    },
  },
  data() {
    return {
      suggestions: [
        { label: '游玩地点', value: '米兰，苏格兰高地，SFDF' },
        { label: '出行日期', value: '2020-05-23 ~ 2020-05-29' },
        { label: '出行时长', value: '7天' },
        { label: '出行人数', value: '4成人，1儿童' },
        { label: '住宿要求', value: '3星级，2间房' },
        {
          label: '其他要求',
          value:
            '行程节奏慢一些，每天赶路不超5小时，适合商务出差，访问名校游学之旅',
        },
      ],
    };
  },
  watch: {
    custom: {
      handler(value) {
        this.suggestions[0].value = value.custom.destination_name;
        this.suggestions[1].value =
          moment(value.custom.beg_date).format('YYYY-MM-DD') +
          ' ~ ' +
          moment(value.custom.end_date).format('YYYY-MM-DD');
        this.suggestions[2].value = value.custom.travel_days + '天';
        this.suggestions[3].value =
          value.custom.travel_num +
          '成人' +
          (value.custom.children_num
            ? `,${value.custom.children_num}儿童`
            : '');
        this.suggestions[4].value =
          (value.custom.hotel_level ? `${value.custom.hotel_level},` : '') +
          value.custom.room_num +
          '间房';
        this.suggestions[5].value = value.custom.personal_demand_demo;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    edit(item) {
      console.log(item);
    },
  },
};
</script>

<style lang="scss" scoped>
.div-9 {
  font-size: 0;
  width: calc(667px + 88px + 50px);
  margin: 0 auto;
  text-align: left;
  .title-9 {
    margin-top: 14vh;
    margin-bottom: 70px;
  }
  .content {
    font-size: 15px;
    color: #fff;
    line-height: 60px;

    .editable {
      margin-left: 8px;
      margin-right: 20px;
    }

    // ::v-deep.el-icon-edit-outline {
    :deep(.el-icon-edit-outline) {
      font-size: 25px;
      height: 30px;
      line-height: 30px;
      vertical-align: middle;
      color: #cfa972;
      cursor: pointer;
    }
  }
}
</style>