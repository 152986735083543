<template>
  <swiper-slide class="swiper-slide-5">
    <div class="div-5">
      <div class="title title-5">
        <div class="icon-person middle"></div>
        <div class="title-c middle">Great！定制客户已收到您的需求</div>
      </div>
      <div class="box-1">
        订单号： {{ order.order.orderId }}
        <br />预计1个工作日内联系您，请留意电话和邮箱
      </div>
      <el-image :src="require('@/assets/index/et_wx_header.png')" class="box-2"></el-image>
      <div class="box-3">添加定制客服微信，直接联系客服</div>
      <!-- <div class="box-4">
        <div class="box-4-item">
          查看定制订单
          <span class="point"></span>
          <span class="point"></span>
          <span class="point"></span>
        </div>
        <div class="box-4-item">
          电话回访
          <span class="point"></span>
          <span class="point"></span>
          <span class="point"></span>
        </div>
        <div class="box-4-item">
          支付订单
          <span class="point"></span>
          <span class="point"></span>
          <span class="point"></span>
        </div>
        <div class="box-4-item">
          查看定制订单
          <span class="point"></span>
          <span class="point"></span>
          <span class="point"></span>
        </div>
        <div class="box-4-item">
          收到确认单
        </div>
      </div> -->
      <!-- <router-link :to="{path: '/'}" class="box-5" >查看定制订单</router-link> -->
    </div>
  </swiper-slide>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SwiperSlide5",
  computed: {
    ...mapState(["custom","order"])
  }
};
</script>

<style lang="scss" scoped>
.div-5 {
  text-align: center;
  .title-5 {
    font-size: 35px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(254, 254, 254, 1);
    margin-bottom: 80px;
  }
  .box-1 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 34px;
    margin-bottom: 30px;
  }
  .box-2 {
    width: 202px;
    height: 202px;
    margin-bottom: 30px;
  }
  .box-3 {
    font-size: 26px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 34px;
    margin-bottom: 10px;
  }
  .box-4 {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 30px;
    &-item {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(206, 168, 113, 1);
      line-height: 34px;
      margin-right: 23px;
      .point {
        margin-right: 3px;
        display: inline-block;
        vertical-align: middle;
        width: 7px;
        height: 7px;
        background: rgba(206, 168, 113, 1);
        border-radius: 50%;
        &:first-child {
          margin-left: 20px;
        }
      }
    }
  }
  .box-5 {
    display: block;
    cursor: pointer;
    width: 300px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    background: rgba(206, 168, 113, 1);
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    margin: 0 auto;
  }
}
</style>
