import { render, staticRenderFns } from "./SwiperSlide0.vue?vue&type=template&id=05807860&scoped=true&"
import script from "./SwiperSlide0.vue?vue&type=script&lang=js&"
export * from "./SwiperSlide0.vue?vue&type=script&lang=js&"
import style0 from "./SwiperSlide0.vue?vue&type=style&index=0&id=05807860&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05807860",
  null
  
)

export default component.exports