<template>
  <swiper-slide class="swiper-slide-7">
    <div class="div-7">
      <div class="title title-7">
        <!-- <img class="icon-person small" :src="image.pv_logo_s"/> -->
        <img
          class="icon-person small"
          :src="require('@/assets/index/logo.png')"
        />
        <div class="title-c small">你想去哪些地方游玩？</div>
      </div>
      <div class="box-1">
        <div class="box-1-1" @click="changeRegion('travel_region_01')">
          <el-image :src="image.pv_p3_ee"></el-image>
          <div class="box-1-3-1">东欧</div>
          <div class="box-1-3-2">EAST AMERICA</div>
        </div>
        <div class="box-1-2" @click="changeRegion('travel_region_02')">
          <el-image :src="image.pv_p3_we"></el-image>
          <div class="box-1-3-1">西欧</div>
          <div class="box-1-3-2">WEST AMERICA</div>
        </div>
        <div class="box-1-2" @click="changeRegion('travel_region_03')">
          <el-image :src="image.pv_p3_se"></el-image>
          <div class="box-1-3-1">南欧</div>
          <div class="box-1-3-2">SORTH AMERICA</div>
        </div>
        <div class="box-1-2" @click="changeRegion('travel_region_04')">
          <el-image :src="image.pv_p3_ne"></el-image>
          <div class="box-1-3-1">北欧</div>
          <div class="box-1-3-2">NORTH AMERICA</div>
        </div>
        <div class="box-1-3" @click="changeRegion('travel_region_05')">
          <el-image :src="image.pv_p3_en"></el-image>
          <div class="box-1-3-1">英国</div>
          <div class="box-1-3-2">England AMERICA</div>
        </div>
      </div>
      <div class="box-2">
        <div class="box-2-l">
          <div
            v-for="(item, index) in showcountryList"
            :key="item.id"
            :class="[
              'box-2-item',
              activeCountryIndex === index ? 'active' : '',
            ]"
            @click="isActiveCountryFunc(index)"
          >
            {{ item.country_nm }}
          </div>
        </div>
        <div
          v-if="countryList.length > 10"
          class="box-2-r box-2-item"
          @click="isShowMoreFunc"
        >
          {{ isAll ? '收起' : '更多' }}
        </div>
      </div>
      <div class="box-3">
        <div
          v-for="(item, index) in cityList"
          :key="item.id"
          :class="['box-3-item', activeCityIndex === index ? 'active' : '']"
          @click="isActiveCityFunc(index, item)"
        >
          {{ item.city_nm }}
        </div>
      </div>
      <el-input
        class="box-4"
        type="textarea"
        placeholder="如果上边没有你想去的地方，可以告诉我"
        v-model="customCity"
      >
      </el-input>
    </div>
  </swiper-slide>
</template>

<script>
import { mapActions } from 'vuex';
import { getCityByCountryId, getCountryByRegion } from '@/api/index';
export default {
  name: 'SwiperSlide7',
  computed: {
    image() {
      return this.$store.state.custom.images;
    },
  },
  data() {
    return {
      countryList: [],
      showcountryList: [],
      cityList: [],
      isAll: true,
      activeCountryIndex: 0,
      activeCityIndex: 0,
      customCity: '',
      region_id: 'travel_region_01',
    };
  },
  watch: {
    customCity: {
      handler(val) {
        this.setCustom({ cus_destination: val });
      },
      immediate: true,
    },
  },
  created() {
    this.getAllCountryFunc();
  },
  methods: {
    ...mapActions(['setCustom']),
    changeRegion(region_id) {
      this.region_id = region_id;
      this.getAllCountryFunc();
    },
    // 国家列表
    getAllCountryFunc() {
      getCountryByRegion({ region_id: this.region_id }).then((response) => {
        this.countryList = response.data;
        this.isAll = false;
        this.showcountryList = this.countryList.slice(0, 10);
        this.getCityByCountryIdFunc({
          country_id: this.countryList[this.activeCountryIndex].id,
        });
      });
    },
    // 国家对应城市
    getCityByCountryIdFunc(data) {
      getCityByCountryId(data).then((response) => {
        this.cityList = response.data;
        this.isActiveCityFunc(0, this.cityList[0]);
      });
    },
    // 展示收起
    isShowMoreFunc() {
      this.isAll = !this.isAll;
      if (this.isAll) {
        this.showcountryList = this.countryList;
      } else {
        this.showcountryList = this.countryList.slice(0, 10);
      }
    },
    // 选择国家
    isActiveCountryFunc(index) {
      this.activeCountryIndex = index;
      this.getCityByCountryIdFunc({
        country_id: this.countryList[this.activeCountryIndex].id,
      });
    },
    // 选择城市
    isActiveCityFunc(index, item) {
      this.activeCityIndex = index;
      this.setCustom({
        destination: item.city_bn,
        destination_name: item.city_nm,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.div-7 {
  font-size: 0;
  width: calc(667px + 88px + 50px);
  margin: 0 auto;
  text-align: left;
  .title-7 {
    margin-top: 14vh;
    margin-bottom: 40px;
  }
  .box-1 {
    :deep(.el-image) {
      height: 140px !important;;
      width: 140px !important;;
    }
    &-1 {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 18%;
      // height: 82px;
      margin-right: 4px;
      &-1 {
        //position: absolute;
        //top: 25px;
        //left: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
      &-2 {
        position: absolute;
        top: 49px;
        left: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
    }
    &-2 {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 18%;
      // height: 82px;
      margin-right: 4px;
      &-1 {
        position: absolute;
        top: 25px;
        left: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
      &-2 {
        position: absolute;
        top: 49px;
        left: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
    }
    &-3 {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 18%;
      // height: 82px;
      &-1 {
        //position: absolute;
        //top: 25px;
        //left: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
      &-2 {
        //position: absolute;
        //top: 49px;
        //left: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
      }
    }
    :deep(.el-image) {
      width: 100%;
      height: 100%;
    }
  }
  .box-2 {
    margin-top: 30px;
    display: flex;
    &-l {
      flex: 1;
    }
    &-r {
      width: 170px;
    }
    &-item {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      padding: 0 8px;
      cursor: pointer;
      &.active {
        color: rgba(206, 168, 113, 1);
        border-bottom: 2px solid rgba(207, 169, 114, 1);
      }
    }
  }
  .box-3 {
    margin-top: 22px;
    &-item {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      padding: 0 8px;
      cursor: pointer;
      border: 1px solid transparent;
      &.active {
        padding: 1px 5px;
        border: 1px solid rgba(207, 169, 114, 1);
        border-radius: 4px;
        color: rgba(207, 169, 114, 1);
      }
    }
  }
  .box-4 {
    margin-top: 25px;
    width: 667px;
    height: 67px;
    background: rgba(255, 255, 255, 0.29);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 24px;
    :deep(.el-textarea__inner) {
      background: transparent;
      border: none;
      color: rgba(255, 255, 255, 1);
      opacity: 0.8;
      width: 100%;
      height: 100%;
    }
  }
}
</style>