<template>
  <swiper-slide class="swiper-slide-12">
    <div class="div-8">
      <div class="title title-8">
        <img
          class="icon-person small icon-8"
          :src="require('@/assets/index/logo.png')"
        />
        <!-- <img class="icon-person small icon-8" :src="image.pv_logo_s"/> -->
        <div class="title-c small">你对住宿有什么要求？想要几间房？</div>
      </div>
      <el-form ref="form" :rules="rules" :model="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="date">
              <el-select v-model="form.type" placeholder="选择要求">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.title"
                  :value="item.title"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-left: 0px;">
          <span style="color: #fff;font-size: 16px;line-height: 36px;">房间数量：</span>
          <el-col :span="20" style="float: right;">
            <el-form-item prop="adult">
              <el-button
                class="number-decrease"
                :disabled="form.room <= 1"
                @click="form.room = Math.max(1, form.room - 1)"
              >
                <i class="el-icon-minus"></i>
              </el-button>
              <el-input
                v-model="form.room"
                @change="roomHandleChange"
                label="房间数量"
              ></el-input>
              <el-button
                class="number-increase"
                :disabled="form.room >= 10"
                @click="form.room = Math.min(10, form.room + 1)"
              >
                <i class="el-icon-plus"></i>
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="customInput">
              <el-input
                class="box-4"
                type="textarea"
                :rows="6"
                placeholder="如果对酒店有其他要求，如酒店位置、酒店配套等，可以告诉我"
                v-model="form.customInput"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </swiper-slide>
</template>

<script>
import { getHotelType } from '@/api/index';
import { mapActions } from 'vuex';
export default {
  name: 'SwiperSlide12',
  computed: {
    image() {
      return this.$store.state.custom.images;
    },
  },
  data() {
    return {
      options: [
        {
          value: '1',
          label: '三星级经济型',
        },
        {
          value: '2',
          label: '四星级舒适型',
        },
        {
          value: '3',
          label: '五星级豪华型',
        },
      ],
      form: {
        type: '',
        room: 1,
        customInput: '',
      },

      rules: {
        type: [{ required: true, message: '请选择要求', trigger: 'blur' }],
        room: [{ required: true, message: '请填写房间数量', trigger: 'blur' }],
        customInput: [{ required: false, trigger: 'blur' }],
      },
    };
  },
  watch: {
    form: {
      handler(value) {
        const { type, room, customInput } = this.form;
        const custom = {
          hotel_level: type,
          room_num: room,
          hotel_demand_demo: customInput,
        };
        this.setCustom(custom);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getHotelTypeFunc();
  },
  methods: {
    ...mapActions(['setCustom']),
    getHotelTypeFunc() {
      getHotelType()
        .then((res) => {
          console.log(res);
          this.options = res.data;
        })
        .catch(() => {});
    },
    roomHandleChange(value) {
      this.form.room = Math.min(10, this.form.room);
      this.form.room = Math.max(1, this.form.room);
    },
  },
};
</script>

<style lang="scss" scoped>
.div-8 {
  width: 667px;
  margin: 0 auto;
  text-align: left;
  .title-8 {
    margin-top: 14vh;
    margin-bottom: 100px;
    .title-c {
      margin-top: 20px;
      font-size: 17px;
      color: #fff;
    }
  }
  :deep(.el-row) {
    margin-bottom: 30px;
  }

  .el-but {
    cursor: pointer;
    width: 302px;
    height: 40px;
    line-height: 40px;
    background: rgba(206, 168, 113, 1);
    border: none;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 0;
    color: rgba(255, 255, 255, 1);
  }
  .el-select {
    :deep(.el-input) {
      width: 100%;
      &:valid {
        color: #fff;
      }
    }
  }
  .number-decrease,
  .number-increase {
    width: 114px;
    height: 100%;
    background: rgba(206, 168, 113, 1);
    border: 1px solid rgba(206, 168, 113, 1);
    border-radius: 0;
  }
  :deep(.el-input) {
    width: calc(100% - 228px);
    .el-input__inner {
      text-align: center;
      &::-webkit-input-placeholder {
        color: #fff;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #fff;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
    }
  }

  :deep(.el-textarea__inner) {
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
    padding: 15px;
    resize: none;

    &::-webkit-input-placeholder {
      color: #fff;
    }
    &::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #fff;
    }
    &:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #fff;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #fff;
    }
  }
}
</style>