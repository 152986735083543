<template>
  <swiper-slide class="swiper-slide-4">
    <div class="title title-4">
      <div class="icon-person middle"></div>
      <div class="title-c middle">Great！定制师已收到您的需求</div>
    </div>
  </swiper-slide>
</template>

<script>
export default {
  name: "SwiperSlide4"
};
</script>

<style lang="scss" scoped>
.title-4 {
  margin-top: 38vh;
}
</style>
