<template>
  <swiper-slide class="swiper-slide-10">
    <div class="div-8">
      <div class="title title-8">
        <img
          class="icon-person small icon-8"
          :src="require('@/assets/index/logo.png')"
        />
        <!-- <img class="icon-person small icon-8" :src="image.pv_logo_s"/> -->
        <div class="title-c small">什么时间出行？几位成人和儿童？</div>
      </div>
      <el-form ref="form" :rules="rules" :model="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="date">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                range-separator="~"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
              <i class="el-icon-date"></i>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-left: 0px;">
          <span style="color: #fff;font-size: 16px;line-height: 36px;">成人人数：</span>
          <el-col :span="20" style="float: right;">
            <el-form-item prop="adult">
              <el-button
                class="number-decrease"
                :disabled="form.adult <= 1"
                @click="form.adult = Math.max(1, form.adult - 1)"
                ><i class="el-icon-minus"></i
              ></el-button>
              <el-input
                v-model="form.adult"
                @change="adultHandleChange"
                label="成人人数"
              ></el-input>
              <el-button
                class="number-increase"
                :disabled="form.adult >= 10"
                @click="form.adult = Math.min(10, form.adult + 1)"
                ><i class="el-icon-plus"></i
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-left: 0px;">
          <span style="color: #fff;font-size: 16px;line-height: 36px;">儿童人数：</span>
          <el-col :span="20" style="float: right;">
            <el-form-item prop="child">
              <el-button
                class="number-decrease"
                :disabled="form.child <= 0"
                @click="form.child = Math.max(0, form.child - 1)"
                ><i class="el-icon-minus"></i
              ></el-button>
              <el-input
                v-model="form.child"
                @change="childHandleChange"
                label="儿童人数"
              ></el-input>
              <el-button
                class="number-increase"
                :disabled="form.child >= 10"
                @click="form.child = Math.min(10, form.child + 1)"
                ><i class="el-icon-plus"></i
              ></el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </swiper-slide>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
export default {
  name: 'SwiperSlide10',
  computed: {
    image() {
      return this.$store.state.custom.images;
    },
  },
  data() {
    return {
      form: {
        date: [],
        adult: 1,
        child: 0,
      },
      rules: {
        date: [{ required: true, message: '请填写日期', trigger: 'blur' }],
        adult: [{ required: true, message: '请填写成年人数', trigger: 'blur' }],
        child: [{ required: true, message: '请填写儿童人数', trigger: 'blur' }],
      },
    };
  },
  watch: {
    form: {
      handler(val) {
        const { date, adult, child } = val;
        const custom = {
          beg_date: date[0],
          end_date: date[1],
          travel_days: moment(date[1]).diff(moment(date[0]), 'days') + 1,
          travel_num: adult,
          children_num: child,
        };
        this.setCustom(custom);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapActions(['setCustom']),
    adultHandleChange(value) {
      this.form.adult = Math.min(10, this.form.adult);
      this.form.adult = Math.max(1, this.form.adult);
    },
    childHandleChange(value) {
      this.form.child = Math.min(10, this.form.child);
      this.form.child = Math.max(0, this.form.child);
    },
  },
};
</script>

<style lang="scss" scoped>
.div-8 {
  width: 667px;
  margin: 0 auto;
  text-align: left;
  .title-8 {
    margin-top: 14vh;
    margin-bottom: 100px;
    .title-c {
      margin-top: 20px;
      font-size: 17px;
      color: #fff;
    }
  }
  :deep(.el-row) {
    margin-bottom: 30px;
  }
  :deep(.el-input) {
    width: calc(100% - 228px);
    .el-input__inner {
      text-align: center;
    }
  }
  .number-decrease,
  .number-increase,
  .el-icon-date {
    width: 114px;
    height: 100%;
    background: rgba(206, 168, 113, 1);
    border: 1px solid rgba(206, 168, 113, 1);
    border-radius: 0;
  }
  .el-icon-date {
    color: #fff;
    line-height: 38px;
    text-align: center;
  }
  .el-but {
    cursor: pointer;
    width: 302px;
    height: 40px;
    line-height: 40px;
    background: rgba(206, 168, 113, 1);
    border: none;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 0;
    color: rgba(255, 255, 255, 1);
  }
  :deep(.el-date-editor--datetimerange.el-input__inner) {
    width: calc(100% - 114px);
  }
}
</style>