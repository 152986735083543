<template>
  <swiper-slide class="swiper-slide-7">
    <div class="div-7">
      <div class="title title-7">
        <img
          class="icon-person small"
          :src="require('@/assets/index/logo.png')"
        />
        <!-- <img class="icon-person small" :src="image.pv_logo_s"/> -->
        <div class="title-c small">太棒啦，只差一步！选择更多个性需求</div>
      </div>
      <div class="content">
        <el-checkbox-group
          v-model="selected"
          class="checkbox-group"
          @change="changeCustom"
        >
          <el-checkbox
            v-for="item in checkboxes"
            :key="item.id"
            :label="item.text"
            class="select-checkbox"
            >{{ item.text }}</el-checkbox
          >
        </el-checkbox-group>
        <el-input
          type="textarea"
          :rows="6"
          placeholder="其他需求"
          v-model="others"
          class="others"
          @change="changeCustom"
        >
        </el-input>
      </div>
    </div>
  </swiper-slide>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'SwiperSlide15',
  computed: {
    image() {
      return this.$store.state.custom.images;
    },
  },
  data() {
    return {
      selected: [],
      others: '',
      checkboxes: [
        { id: '1', text: '行程节奏慢一些' },
        { id: '2', text: '品尝当地特色美食' },
        { id: '3', text: '每天赶路不超5小时' },
        { id: '4', text: '适合蜜月的海岛' },
        { id: '5', text: '适合老年人的路线' },
        { id: '6', text: '适合摄影拍照的线路' },
        { id: '7', text: '适合商务出差' },
        { id: '8', text: '徒步探险刺激之旅' },
        { id: '9', text: '访问名校游学之旅' },
      ],
    };
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions(['setCustom']),
    changeCustom() {
      this.customSuggestions =
        this.selected.join(',') + (this.others ? `,${this.others}` : '');
      const custom = {
        personal_demand_demo: this.customSuggestions,
      };
      this.setCustom(custom);
    },
  },
};
</script>

<style lang="scss" scoped>
.div-7 {
  font-size: 0;
  width: calc(667px + 88px + 50px);
  margin: 0 auto;
  text-align: left;
  .title-7 {
    margin-top: 14vh;
    margin-bottom: 70px;
  }
  .select-checkbox {
    color: #fff;
    width: 29%;
    height: 40px;
    line-height: 40px;

    :deep(.el-checkbox__inner) {
      background: rgba(255, 255, 255, 0.5);
      border: none;
      transform: scale(1.2);
      box-sizing: content-box;
      &::after {
        left: 5px;
        height: 7px;
      }
      &:hover {
        border-color: #cfa972;
      }
    }
    :deep(.el-checkbox__input.is-checked) {
      .el-checkbox__inner {
        background-color: #cfa972;
        border-color: #cfa972;
      }
    }
    :deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
      color: #fff;
    }
  }
  .others {
    margin-top: 40px;
    :deep(.el-textarea__inner) {
      background: rgba(255, 255, 255, 0.5);
      border: none;
      border-radius: 0;
      box-sizing: content-box;
      color: #fff;
      font-size: 12px;
      padding: 15px;
      resize: none;

      &::-webkit-input-placeholder {
        color: #fff;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #fff;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #fff;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #fff;
      }
    }
  }
}
</style>