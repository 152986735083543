<template>
  <swiper-slide class="swiper-slide-3">
    <div class="div-3">
      <div class="box-1">请确认当前填写的手机号是否正确</div>
      <div class="box-2">{{ custom.custom.customer_tel }}</div>
      <div class="box-3">意向单提交后，我们会将此手机号注册为游遍欧洲会员</div>
      <div class="box-5">（默认密码为：123456）</div>
      <div class="box-4" @click="confirm">确定</div>
    </div>
  </swiper-slide>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { postCustomization } from "@/api";
import { clientRegister } from "@/api/index";
export default {
  name: "SwiperSlide3",
  computed: {
    ...mapState(["custom"])
  },
  methods: {
    ...mapActions(["setOrder","clearCustom","clearOrder"]),
    confirm() {
      if (this.custom.custom && this.custom.custom.customer_tel) {
        debugger
        let obj = {};
        obj.account = this.custom.custom.customer_tel;
        obj.nickname = this.custom.custom.customer_tel;
        obj.secret = "123456"
        obj.type = 101;
        clientRegister(obj).then(response => {
          if (response.code === 200) {
            postCustomization(this.custom.custom).then(res => {
              if(res.code === 200) {
                this.clearOrder()
                const order = {
                  orderId: res.data.id
                }
                this.setOrder(order);
                this.clearCustom();
                this.$parent.$parent.slideNext(14);
              }
            });
          } else {
            this.$message({
              message: response.message || "注册失败",
              type: "error"
            });
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.div-3 {
  width: 902px;
  height: 492px;
  background: rgba(255, 255, 255, 1);
  border-radius: 18px;
  margin: 0 auto;
  margin-top: 20vh;
  text-align: center;
  padding: 40px;
  box-sizing: border-box;
  .box-1 {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 80px;
  }
  .box-2 {
    font-size: 25px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 25px;
  }
  .box-3 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
  }
  .box-5 {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: rgba(51, 51, 51, 1);
    margin-bottom: 50px;
  }
  .box-4 {
    cursor: pointer;
    width: 250px;
    height: 68px;
    line-height: 68px;
    background: rgba(61, 126, 255, 1);
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    margin: 0 auto;
  }
}
</style>
