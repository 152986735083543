<template>
  <swiper-slide class="swiper-slide-2">
    <div class="div-2">
      <div class="title title-2">
        <!-- <img class="icon-person small icon-2" :src="image.pv_logo_s"/> -->
        <img class="icon-person small icon-2" :src="require('@/assets/index/logo.png')"/>
        <div class="title-c small">我怎样方便联系您？</div>
      </div>
      <el-form ref="form" :rules="rules" :model="form">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="name">
              <el-input placeholder="先生/女士" v-model="form.name"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item prop="tel_code">
              <el-select
                size="medium"
                v-model="form.tel_code"
                filterable
                placeholder="选择国家"
              >
                <el-option
                  v-for="(item, index) in article"
                  :key="index"
                  :label="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item prop="tel">
              <el-input placeholder="请填写手机号" v-model="form.tel">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="weChat">
              <el-input placeholder="请填写您的微信号" v-model="form.weChat">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="address">
              <el-input placeholder="请填写您的邮箱地址" v-model="form.address">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12" :offset="6">
            <el-button class="el-but" @click="submitForm('form')"
              >提交</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
  </swiper-slide>
</template>

<script>
import article from "@/utils/article";
import { postCustomization } from "@/api";
import { mapState, mapActions } from "vuex";
export default {
  name: "SwiperSlide2",
  computed:{
    ...mapState(["custom"]),
    image() {
      return this.$store.state.custom.images;
    },
  },
  data() {
    return {
      article: article,
      form: {
        name: "", // 意见
        tel_code: "",
        tel: "", // 姓名
        weChat: "", // 联系方式
        address: "" // 联系方式
      },
      rules: {
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
        tel_code: [{ required: true, message: "请选择国家", trigger: "blur" }],
        tel: [{ required: true, message: "请填写手机号", trigger: "blur" }],
        weChat: [
          { required: true, message: "请填写您的微信号", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    ...mapActions(["setOrder", "clearCustom", "setCustom"]),
    submitForm(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          let arr = /\w*-[\u4e00-\u9fa5]*-(\d*)/.exec(this.form.tel_code);
          var tel_code = arr && arr.length && arr[1];
          const { name, tel, weChat, address } = this.form;
          const custom = {
            customer_name: name,
            customer_country_code: tel_code,
            customer_tel: tel,
            customer_wx: weChat,
            customer_email: address,
            submit_time: new Date()
          };
          this.setCustom(custom);
          if (custom.customer_tel) {
              this.$parent.$parent.slideNext(13);
          }
          // postCustomization(this.custom.custom).then(res => {
          //   if (res.code === 200) {
          //     this.setOrder(res.data.id);
          //     this.clearCustom();
          //     this.$router.push({
          //       path: "/"
          //     });
          //   }
          // });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.div-2 {
  width: 667px;
  margin: 0 auto;
  .title-2 {
    margin-top: 14vh;
    margin-bottom: 100px;
    .title-c {
      margin-top: 20px;
      font-size: 17px;
      color: #fff;
    }
  }
  .el-but {
    cursor: pointer;
    width: 302px;
    height: 40px;
    line-height: 40px;
    background: rgba(206, 168, 113, 1);
    border: none;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    padding: 0;
    color: rgba(255, 255, 255, 1);
  }
}
</style>
